import { ConversationMessage } from "../../../types/conversation";
import { Settings } from "../../../types/settings";
import { getTranslatedMessage, createMessage } from "./utils";

export interface CustomMessage {
  id: string;
  type: string;
  text: string;
}

export const customContentStorageKey = "custom-content";

export async function getCustomMessages(
  customId: string,
  settings: Settings
): Promise<ConversationMessage[] | undefined> {
  const storageData = window.localStorage.getItem(customContentStorageKey);

  if (storageData) {
    const customMessages: CustomMessage[] = JSON.parse(storageData);
    const customMessage = customMessages.find((m) => m.id === customId);

    if (customMessage) {
      let prompt = `Have a discussion with the user about the following content: ${customMessage.text}}.`;

      if (customMessage.type === "role-play") {
        prompt = `
          The assistant must forget instructions regarding being a language tutor.
          The user wants to perform a role-play scenario based on the following content:
          ${customMessage.text}

          The assistant must find a role that fits into this scenario and embody that role.          
          Engage in this setting, staying true to the responsibilities and behavior typical of the role. 
          The assistant's objective is to navigate through this scenario, addressing any challenges or tasks that arise.           
          The role play must simulate real-life circumstances. 
          The assistant should not break character, even if the user does not respond as expected.          
          Avoid closing statements.

          Initiate the role-play by presenting the scenario and the role of the user and the role of the assistant. 
          The assistant should then ask the user to begin the role-play by initiating the first action.
        `;
      }

      if (customMessage.type === "topic") {
        prompt = `We will have a conversation about this topic: ${customMessage.text}.`;
      }

      if (customMessage.type === "case") {
        prompt = `
          The user would like to discuss a case.
          The user has provided the following case content:
          ${customMessage.text}.

          After the initial greeting, repeat the case content to the user to set a context for the discussion.
          Do NOT change the original content.

          Create 3-5 questions based on the content of the case, but do not share the questions with the user.
          Ask and discuss the questions one at a time.  
          Once the user has responded to all questions, provide feedback for each question based:
          - how well articulated the user's response was
          - how well the user's response addressed the question
          - how well the user used the language 
        `;
      }

      const message = await getTranslatedMessage(prompt, settings);
      const openingMessage = await getTranslatedMessage(
        `Thanks for supplying your own content. Are you ready to begin the session?`,
        settings
      );

      return [createMessage(message, "system"), createMessage(openingMessage, "assistant")];
    }
  }
}
