import { useMemo } from "react";
import { characterFromVoice } from "../services/characters/character-from-voice";
import { useSettings } from "./use-settings";

export function useCharacterFromVoice() {
  const { settings } = useSettings();

  const character = useMemo(() => {
    if (settings.voicePreference) {
      return characterFromVoice(settings.voicePreference);
    }

    return undefined;
  }, [settings.voicePreference]);

  return character;
}
