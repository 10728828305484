import { Button, Image, Flex, Text, Box } from "@chakra-ui/react";
import { useContext } from "react";
import { LocalizationContext } from "../hooks/use-localization";

interface Props {
  image?: string;
  buttonText: any;
  buttonTextColor?: string;
  buttonBackgroundColor: string;
  descriptionTextColor?: string;
  bgColor?: string;
  description: string;
  isLoading?: boolean;
  borderColor?: string;
  onClick: () => void;
}

export default function TemplateCard(props: Props) {
  const { l } = useContext(LocalizationContext);

  const height = "200px";
  const width = "100%";

  const hasImage = props.image && props.image !== "";
  const bgColor = props.bgColor ? props.bgColor : "#1d101c";
  const buttonText = l[props.buttonText as keyof typeof l];
  const description = l[props.description as keyof typeof l];

  return (
    <Flex
      alignItems={"center"}
      minH={height}
      maxW={{ base: "100%" }}
      pos={"relative"}
      direction={"column"}
      flex={1}
      justifyContent={"center"}
      mb={{ base: 5, sm: 0 }}
      overflow={"hidden"}
      borderRadius={"8px"}
      backgroundColor={bgColor}
      border="1px solid"
      borderColor={props.borderColor || "transparent"}
    >
      {hasImage && (
        <Image
          objectFit="cover"
          maxW={{ base: "100%" }}
          w={width}
          minH={height}
          src={props.image}
          alt={buttonText}
          pos={"absolute"}
          borderRadius={"8px"}
          opacity={0.3}
          _before={{
            content: "''",
            position: "absolute",
            backgroundColor: props.buttonBackgroundColor,
          }}
        />
      )}
      <Flex zIndex={10} justifyContent="center" alignItems="center" flexDir={"column"}>
        <Button
          color={props.buttonTextColor || "#000"}
          mt={10}
          boxShadow={hasImage ? "dark-lg" : ""}
          backgroundColor={props.buttonBackgroundColor}
          variant="solid"
          size={"md"}
          onClick={props.onClick}
          minW={"150px"}
          maxW="80%"
          isLoading={props.isLoading}
          _disabled={{
            opacity: 1,
            cursor: "not-allowed",
          }}
          _hover={{
            backgroundColor: "brand.gray.300",
            color: "#000",
          }}
        >
          <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxW="100%">
            {buttonText}
          </Box>
        </Button>

        <Text
          color={props.descriptionTextColor || "white"}
          fontSize={"xs"}
          px={5}
          mt={4}
          textAlign={"center"}
          fontWeight={"bold"}
          textShadow={hasImage ? "0px 0px 5px #000" : 0}
          minH="50px"
        >
          {description}
        </Text>
      </Flex>
    </Flex>
  );
}
