import { staticCharacterListWithBios } from "./static-character-list-with-bios";

export interface Character {
  langCode: string;
  id: string;
  avatarName: string;
  displayName: string;
  gender: string;
  bio: string;
}

export function characterFromVoice(id: string) {
  const character: Character | undefined = staticCharacterListWithBios.find((c) => c.id === id);

  return character;
}
