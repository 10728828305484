import { Text, Box, Heading, Flex } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { IoIosArrowBack } from "react-icons/io";

interface Props {
  children: React.ReactNode;
  asidePosition?: React.ReactNode;
  subHeading?: string;
  subHeadingComponent?: React.ReactNode;
  backHref?: string;
  showBackButton?: boolean;
  mt?: number;
  id?: string;
}

export default function PageHeading(props: Props) {
  const router = useRouter();

  const onBackClick = () => {
    if (props.backHref) {
      router.push(props.backHref);
    } else {
      router.back();
    }
  };

  const mt = props.showBackButton ? 5 : props.mt || 2;

  return (
    <Flex
      flexDir={{ base: "column", lg: "row" }}
      justifyContent={{ lg: "space-between" }}
      alignItems={{ base: "flex-start", lg: "center" }}
      mb={7}
    >
      <Box position="relative">
        {props.showBackButton && (
          <Flex
            cursor={"pointer"}
            _hover={{ color: "brand.gray.700" }}
            onClick={onBackClick}
            marginLeft="-4px"
            mb={2}
            color="brand.gray.400"
            alignItems={"center"}
            position={"absolute"}
            top="-7px"
          >
            <IoIosArrowBack fontSize="sm" />
            <Box data-tid="back-button" fontSize="xs" lineHeight={1} ml={1} fontWeight="bold">
              Back
            </Box>
          </Flex>
        )}
        <Flex mt={mt}>
          <Heading id={props.id} as="h1" fontSize="2xl" lineHeight={1} color={"gray.800"}>
            {props.children}
          </Heading>
        </Flex>

        {props.subHeading && (
          <Text mt={2} fontSize={"sm"} color={"gray.600"}>
            {props.subHeading}
          </Text>
        )}

        {props.subHeadingComponent && (
          <Box mt={2} fontSize={"sm"} color={"gray.600"}>
            {props.subHeadingComponent}
          </Box>
        )}
      </Box>

      {props.asidePosition && <Box mt={2}> {props.asidePosition}</Box>}
    </Flex>
  );
}
