import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
  Input,
  Button,
  Flex,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { useCallback, useContext, useState } from "react";
import { useLocalStorage } from "../../../hooks/use-local-storage";
import {
  CustomMessage,
  customContentStorageKey,
} from "../../../services/prompts/chat/custom-messages";
import { v4 as uuidv4 } from "uuid";
import { LocalizationContext } from "../../../hooks/use-localization";
import GenericModalBody from "./generic-template/generic-modal-body";
import { usePromptTemplates } from "../../../hooks/use-prompt-templates";
import { PromptTemplateConfigType } from "../../../configuration/types";
import { siteConfig } from "../../../configuration/config";

export interface Props {
  isOpen: boolean;
  selectionType: PromptTemplateConfigType;
  onClose: () => void;
}

export default function TemplateModal(props: Props) {
  const { l } = useContext(LocalizationContext);
  const [customContent, setCustomContent] = useState("");
  const [customContentError, setCustomContentError] = useState("");
  const { storageData, setStorageData } = useLocalStorage<CustomMessage[]>(customContentStorageKey);
  const template = siteConfig.templates?.find((t) => t.type === props.selectionType);
  const { translatedPromptTemplates, promptTemplatesAreLoading } = usePromptTemplates();

  const onSubmitCustomContent = useCallback(
    (content: string) => {
      if (!content) {
        setCustomContentError("Please enter some content");
        return;
      } else if (content.length > 2000) {
        setCustomContentError(`You entered ${content.length} characters but the maximum is 2000`);
        return;
      }

      const id = uuidv4();
      const existingStorageData = storageData || [];

      setStorageData([
        ...existingStorageData,
        {
          id,
          type: props.selectionType,
          text: content,
        },
      ]);

      setCustomContent("");
      setTimeout(() => {
        window.location.href = `/app/chat?custom=${id}`;
      }, 100);
    },
    [storageData, props.selectionType, setStorageData],
  );

  if (!template) {
    return <div />;
  }

  const title = l[template.titleKey as keyof typeof l];
  const description = l[template.descriptionKey as keyof typeof l];

  return (
    <Modal size="xl" isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>

        <ModalCloseButton />
        <ModalBody>
          <Text mb={3}>{description}</Text>

          <Tabs>
            {!siteConfig.hideCustomContent &&
              (props.selectionType === "topic" || props.selectionType === "role-play") && (
                <TabList>
                  <Tab>{l["dashboard.template.modal.curated"]}</Tab>
                  <Tab>{l["dashboard.template.modal.custom"]}</Tab>
                </TabList>
              )}

            <TabPanels>
              <TabPanel px={0}>
                {promptTemplatesAreLoading ? (
                  <Flex justifyContent={"center"} alignItems={"center"} minH="150px">
                    <Spinner size="lg" />
                  </Flex>
                ) : (
                  <>
                    <GenericModalBody
                      templates={translatedPromptTemplates}
                      type={props.selectionType}
                    />
                  </>
                )}
              </TabPanel>
              <TabPanel>
                <Text mt={3} mb={3} fontSize="sm">
                  {l["dashboard.template.modal.inputContent"]}
                </Text>

                <Textarea
                  onChange={(e) => {
                    setCustomContentError("");
                    setCustomContent(e.target.value);
                  }}
                />

                <Box minH={30} mt={2}>
                  {customContentError.length > 0 ? (
                    <Text fontSize={"sm"} color="brand.red.400" textAlign={"right"}>
                      {customContentError}
                    </Text>
                  ) : null}
                </Box>
                <Flex justifyContent={"flex-end"}>
                  <Button
                    onClick={() => onSubmitCustomContent(customContent)}
                    size="sm"
                    mt={3}
                    colorScheme="brand.secondary"
                  >
                    {l["app.start"]}
                  </Button>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
