import { siteConfig } from "../../../configuration/config";
import { PromptTemplateDTO } from "../../../database/entity/PromptTemplate";

export const topicsV2: PromptTemplateDTO[] = siteConfig?.customContent?.topics || [
  {
    type: "topic",
    status: "listed",
    slug: "travel-experiences-favorite-destinations",
    title: "Travel experiences and favorite destinations",
    description: "Share your travel stories and favorite places to visit.",
    prompt:
      "Discuss your travel experiences and share your favorite destinations along with the stories behind them.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "hobbies-and-interests",
    title: "Hobbies and interests",
    description: "Discuss your favorite hobbies and personal interests.",
    prompt: "Talk about your hobbies and interests, and what makes them special to you.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "food-and-cooking",
    title: "Food and cooking",
    description: "Talk about your favorite foods and cooking techniques.",
    prompt: "Share your love for food and cooking, including your favorite recipes and techniques.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "personal-goals-aspirations",
    title: "Personal goals and aspirations",
    description: "Share your personal goals and future aspirations.",
    prompt: "Discuss your personal goals and aspirations, and how you plan to achieve them.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "pop-culture-entertainment",
    title: "Pop culture and entertainment",
    description: "Discuss the latest in pop culture and entertainment.",
    prompt:
      "Talk about current trends in pop culture and entertainment, and your thoughts on them.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "family-relationships",
    title: "Family and relationships",
    description: "Talk about family dynamics and relationships.",
    prompt: "Share your experiences and views on family dynamics and relationships.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "sports-athletics",
    title: "Sports and athletics",
    description: "Discuss your favorite sports and athletic activities.",
    prompt: "Talk about your favorite sports, athletic activities, and what they mean to you.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "career-aspirations-job-satisfaction",
    title: "Career aspirations and job satisfaction",
    description: "Share your career goals and thoughts on job satisfaction.",
    prompt: "Discuss your career aspirations and what makes you feel satisfied in your job.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "health-fitness",
    title: "Health and fitness",
    description: "Discuss health tips and fitness routines.",
    prompt: "Share your health tips, fitness routines, and how they impact your life.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "technology-innovation",
    title: "Technology and innovation",
    description: "Talk about the latest in technology and innovative ideas.",
    prompt: "Discuss the latest advancements in technology and innovative ideas that excite you.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "books-literature",
    title: "Books and literature",
    description: "Discuss your favorite books and literary works.",
    prompt: "Share your favorite books and literary works, and why they resonate with you.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "philosophy-existential-questions",
    title: "Philosophy and existential questions",
    description: "Share your thoughts on philosophy and existential questions.",
    prompt: "Discuss philosophical ideas and existential questions that intrigue you.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "music-musicians",
    title: "Music and musicians",
    description: "Talk about your favorite music and musicians.",
    prompt: "Share your favorite music and musicians, and how they influence your life.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "art-artists",
    title: "Art and artists",
    description: "Discuss your favorite art and artists.",
    prompt: "Talk about your favorite art pieces and artists, and what you appreciate about them.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "education-learning",
    title: "Education and learning",
    description: "Share your views on education and the learning process.",
    prompt: "Discuss your thoughts on education and the most effective ways to learn.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "environmental-issues-sustainability",
    title: "Environmental issues and sustainability",
    description: "Discuss environmental issues and sustainability practices.",
    prompt: "Share your views on environmental issues and the importance of sustainability.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "spirituality-religion",
    title: "Spirituality and religion",
    description: "Talk about spirituality and religious beliefs.",
    prompt: "Discuss your spiritual journey and religious beliefs, and how they shape your life.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "personal-finance-money-management",
    title: "Personal finance and money management",
    description: "Share tips on personal finance and money management.",
    prompt: "Talk about personal finance strategies and tips for effective money management.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "cinema-filmmaking",
    title: "Cinema and filmmaking",
    description: "Discuss your favorite films and filmmaking techniques.",
    prompt: "Share your favorite films and discuss the techniques used in filmmaking.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "gardening-plant-care",
    title: "Gardening and plant care",
    description: "Share tips on gardening and caring for plants.",
    prompt: "Discuss your gardening experiences and share tips for plant care.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "history-historical-events",
    title: "History and historical events",
    description: "Discuss significant historical events and their impact.",
    prompt: "Talk about important historical events and their lasting impact on society.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "video-games-gaming-culture",
    title: "Video games and gaming culture",
    description: "Talk about your favorite video games and gaming culture.",
    prompt: "Share your favorite video games and discuss the culture surrounding gaming.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "travel-photography-videography",
    title: "Travel photography and videography",
    description: "Share tips on travel photography and videography.",
    prompt: "Discuss your experiences with travel photography and videography, and share tips.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "fashion-style-trends",
    title: "Fashion and style trends",
    description: "Discuss the latest trends in fashion and style.",
    prompt: "Talk about current fashion trends and share your style inspirations.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "science-scientific-discoveries",
    title: "Science and scientific discoveries",
    description: "Discuss recent scientific discoveries and advancements.",
    prompt: "Share your thoughts on recent scientific discoveries and their potential impact.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "psychology-human-behavior",
    title: "Psychology and human behavior",
    description: "Talk about the study of psychology and human behavior.",
    prompt: "Discuss insights from psychology and how they explain human behavior.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "diy-projects-crafts",
    title: "DIY projects and crafts",
    description: "Share your favorite DIY projects and crafting ideas.",
    prompt: "Discuss your DIY projects and share creative crafting ideas.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "pets-animal-care",
    title: "Pets and animal care",
    description: "Talk about your pets and tips for animal care.",
    prompt: "Share stories about your pets and provide tips on caring for animals.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "cooking-techniques-recipes",
    title: "Cooking techniques and recipes",
    description: "Share your favorite cooking techniques and recipes.",
    prompt: "Discuss your favorite cooking techniques and share delicious recipes.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "space-exploration-astronomy",
    title: "Space exploration and astronomy",
    description: "Talk about space exploration and astronomical discoveries.",
    prompt: "Discuss the latest in space exploration and exciting astronomical discoveries.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "languages-linguistics",
    title: "Languages and linguistics",
    description: "Discuss languages and the study of linguistics.",
    prompt: "Talk about different languages and the fascinating field of linguistics.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "volunteering-community-service",
    title: "Volunteering and community service",
    description: "Share your experiences with volunteering and community service.",
    prompt: "Discuss your volunteer work and the importance of community service.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "outdoor-activities-adventure-sports",
    title: "Outdoor activities and adventure sports",
    description: "Talk about your favorite outdoor activities and adventure sports.",
    prompt: "Share your experiences with outdoor activities and adventure sports.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "home-decor-interior-design",
    title: "Home decor and interior design",
    description: "Discuss home decor ideas and interior design tips.",
    prompt: "Share your home decor ideas and tips for interior design.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "automobiles-motoring",
    title: "Automobiles and motoring",
    description: "Talk about cars and motoring experiences.",
    prompt: "Discuss your favorite cars and share your motoring experiences.",
  },
  {
    type: "topic",
    status: "listed",
    slug: "digital-art-graphic-design",
    title: "Digital art and graphic design",
    description: "Share your work and tips in digital art and graphic design.",
    prompt: "Discuss your digital art projects and provide tips on graphic design.",
  },
];
