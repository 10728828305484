import TemplateCard from "../template-card";
import { useState } from "react";
import TemplateModal from "./template-modal";
import { createPortal } from "react-dom";
import { siteConfig } from "../../configuration/config";
import { PromptTemplateConfigType } from "../../configuration/types";

interface Props {
  templateSelection: "new-chat" | "dashboard";
}

export default function ChatTemplates(props: Props) {
  const [templateButtonLoaderIndex, setTemplateButtonLoaderIndex] = useState(-1);
  const [templateModalProps, setTemplateModalProps] = useState<{
    isOpen: boolean;
    selectionType?: PromptTemplateConfigType;
  }>({
    isOpen: false,
    selectionType: undefined,
  });

  // const templates =
  //   props.templateSelection === "dashboard"
  //     ? dashboardTemplates
  //     : newChatTemplates;

  const templates = siteConfig.templates || [];

  return (
    <>
      {createPortal(
        templateModalProps.selectionType ? (
          <TemplateModal
            isOpen={templateModalProps.isOpen}
            selectionType={templateModalProps.selectionType}
            onClose={() => setTemplateModalProps({ isOpen: false, selectionType: undefined })}
          />
        ) : null,
        document.body,
      )}
      {templates
        .filter((t) => (props.templateSelection === "dashboard" ? t.type !== "free-talk" : true))
        .map((template, index) => (
          <TemplateCard
            buttonText={template.titleKey}
            image={template.backgroundImagePath}
            key={index}
            buttonBackgroundColor={template.buttonBackgroundColor}
            buttonTextColor={template.buttonTextColor}
            bgColor={template.backgroundColor}
            descriptionTextColor={template.descriptionTextColor}
            borderColor={template.borderColor}
            description={template.descriptionKey}
            isLoading={templateButtonLoaderIndex === index}
            onClick={() => {
              if (template.type === "free-talk") {
                setTemplateButtonLoaderIndex(index);
                window.location.href = `/app/chat`;
              } else {
                setTemplateModalProps({
                  selectionType: template.type,
                  isOpen: true,
                });
              }
            }}
          />
        ))}
    </>
  );
}
