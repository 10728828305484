import { logger } from "@sentry/utils";
import { fetchApi } from "./fetch-api";

export async function fetchTranslation(text: string, fromLang: string, toLang: string) {
  const body = {
    text,
    from: fromLang,
    to: toLang,
  };

  try {
    const result = await fetchApi<{ text: string }>(
      "/translator",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      },
      5000,
    );
    return result.text;
  } catch (err) {
    logger.error("Failed to fetch translation for " + text, err);
    return "";
  }
}
