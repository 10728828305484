import { siteConfig } from "../../../configuration/config";
import { PromptTemplateDTO } from "../../../database/entity/PromptTemplate";

export interface Assessment {
  question: string;
  expectation: string;
}

export interface Case {
  id: string;
  title: string;
  case: string;
  assessment: Assessment[];
}

const casesToTemplates = (cases: Case[]): PromptTemplateDTO[] => {
  return cases.map((c) => ({
    type: "case",
    status: "listed",
    slug: c.id,
    title: c.title,
    description: "",
    prompt: c.assessment
      .map(
        (a) =>
          `
            Question: ${a.question}     
            Expectation: ${a.expectation}
          `,
      )
      .join("\n"),
    article: c.case,
  }));
};

export const casesV2: PromptTemplateDTO[] = casesToTemplates(
  siteConfig?.customContent?.cases || [
    {
      id: "tourism-impact",
      title: "Tourism and Local Culture",
      case: "The picturesque island of Santorina has recently become a hotspot for international tourists due to its breathtaking views, unique local culture, and rich historical background. The influx of tourists has contributed significantly to the island's economy, creating jobs in the hospitality sector and boosting local businesses. However, some residents express concerns regarding the erosion of traditional practices and the rising cost of living caused by the surge in property demand from outsiders. Moreover, environmentalists warn about the strain on natural resources as tourism infrastructure continues to expand.",
      assessment: [
        {
          question:
            "Evaluate the economic benefits of tourism for Santorina and contrast them with the potential negative consequences for the local population.",
          expectation:
            "Candidates should discuss the economic gain, such as increased employment and business revenue, and balance it against issues like cost of living increases and the potential for cultural dilution.",
        },
        {
          question:
            "Discuss the impact of tourism on the preservation of local cultures and traditions on Santorina.",
          expectation:
            "Interviewees should consider both the positive aspects, like cultural exchange and promotion, and the negative aspects, such as the commercialization of culture and potential loss of authenticity.",
        },
        {
          question:
            "How could Santorina develop sustainable tourism practices that support the economy while protecting cultural and environmental resources?",
          expectation:
            "For this question, candidates should propose strategies for sustainable tourism development that balance economic growth with cultural and environmental preservation, such as regulatory measures, community engagement, and education programs.",
        },
      ],
    },
    {
      id: "multinational-communication",
      title: "Effective Communication in a Multinational Corporation",
      case: "Within the dynamic setting of Globex Corp, a multinational company with a diverse workforce spanning numerous countries and cultures, communication has become a notable challenge. The company's management has identified several incidents where miscommunication led to project delays, decreased morale, and even costly errors. These issues were exacerbated by language barriers, cultural misinterpretations, and the use of various communication technologies that are not uniformly adopted across all departments. To address these challenges, Globex Corp seeks to develop a comprehensive communication strategy that would not only bridge gaps and align with the company's vision but also respect the cultural nuances of its global workforce.",
      assessment: [
        {
          question:
            "What are the key components that should be included in Globex Corp's communication strategy to address the identified issues?",
          expectation:
            "Candidates should suggest elements such as standardized communication tools, language training, cross-cultural competence workshops, clear protocols for different types of communications, and inclusion of local cultural consultants.",
        },
        {
          question:
            "How can technology be used to improve communication among Globex Corp's international teams, and what potential pitfalls should be avoided?",
          expectation:
            "Expectations include suggestions for collaborative software, translation tools, virtual meeting platforms, while also being aware of issues such as technology adoption resistance, managing different time zones, and data security.",
        },
        {
          question:
            "What metrics or indicators could Globex Corp use to measure the effectiveness of the new communication strategy?",
          expectation:
            "Interviewees should describe quantifiable indicators such as decreased project turnaround times, reduced error rates, employee satisfaction scores, and uptake rates of new communication tools.",
        },
      ],
    },
    {
      id: "diet-exercise-health",
      title: "Health and Lifestyle Choices",
      case: "Local authorities have launched a public health campaign called 'Healthy Living' aimed at reducing obesity levels in the community by 15% over the next five years. The campaign promotes a comprehensive approach, focusing on improving dietary habits and increasing daily physical activity among residents. A variety of programs are in place, such as subsidized fitness classes, free nutritional workshops, and an app that helps users track their food intake and exercise. While these initiatives could play a crucial role in enhancing community health, challenges such as cultural food preferences, economic disparities, and resistance to behavior change could hinder the campaign’s success.",
      assessment: [
        {
          question:
            "What factors should be considered when designing a public health campaign focused on diet and exercise?",
          expectation:
            "Interviewers should expect candidates to discuss the importance of understanding cultural, socio-economic, and psychological factors that influence health and lifestyle choices.",
        },
        {
          question:
            "How might economic disparities affect the effectiveness of the 'Healthy Living' campaign?",
          expectation:
            "Candidates should consider the influence of economic status on access to healthy foods and fitness facilities, and discuss potential strategies to address these issues.",
        },
        {
          question:
            "If you were to lead this campaign, how would you measure its success, and what steps would you take if the desired change in obesity levels is not being achieved?",
          expectation:
            "Interviewees are expected to propose methods for monitoring and evaluating the campaign’s outcomes, as well as adaptive strategies if initial results are not on target.",
        },
      ],
    },
    {
      id: "tech-integration-classroom",
      title: "Integrating Technology in the Classroom",
      case: "An urban school district is introducing a 1:1 laptop initiative to enhance learning experiences in the classroom. Each student will be provided with a personal laptop to use for school-related tasks. The integration of technology includes access to online educational resources, interactive software for learning, as well as training for teachers to effectively incorporate these tools into their teaching strategies. While the initiative promises to make education more accessible and engaging, it also presents challenges such as the potential for digital distraction, the need for robust cybersecurity measures, and the demand for continuous technical support.",
      assessment: [
        {
          question:
            "What are the potential benefits of implementing a 1:1 laptop initiative in classrooms?",
          expectation:
            "The response should include discussions on the accessibility of information, personalized learning opportunities, and enhanced student engagement through interactive digital tools.",
        },
        {
          question:
            "What might be some of the challenges faced by the school district in integrating technology into classrooms?",
          expectation:
            "Expectations are to address issues such as distraction due to inappropriate use of technology, the need for training teachers and students to use these tools effectively, and the importance of maintaining students' data privacy and system security.",
        },
        {
          question:
            "How would you address the issue of digital equity to ensure all students equally benefit from the technology?",
          expectation:
            "Candidates should be able to suggest measures such as ensuring reliable internet access, offering technical support and resources for low-income families, and providing training to bridge the digital skills gap.",
        },
      ],
    },
    {
      id: "sustainable-fashion",
      title: "Sustainable Fashion Initiative",
      case: "A clothing company decides to launch a new line of products marketed as 'Sustainable Fashion.' The line includes apparel made from recycled materials, organic cotton, and other eco-friendly resources. The initiative not only focuses on using environmentally sustainable materials, but also promotes fair labor practices and aims to raise consumer awareness about the environmental impacts of the fashion industry. Despite the well-intended efforts, the higher production costs associated with sustainable materials translate into higher prices for consumers. This raises questions about accessibility and the effectiveness of ethical consumerism in driving industry-wide change.",
      assessment: [
        {
          question:
            "Discuss the potential environmental and social benefits of the sustainable fashion line introduced by the company.",
          expectation:
            "Candidates are expected to analyze the beneficial impacts such as reduced waste, less water pollution, support for fair labor practices, and overall contribution to ethical consumerism.",
        },
        {
          question:
            "How might the higher costs associated with sustainable materials affect consumer purchasing behavior?",
          expectation:
            "Interviewees should explore the challenges posed by higher pricing, including possible limitations on consumer adoption and the dilemma of balancing cost with ethical considerations.",
        },
        {
          question:
            "As the marketing manager of this clothing company, how would you effectively communicate the value of sustainable fashion to consumers?",
          expectation:
            "The candidate is expected to outline strategies to educate and motivate consumers, highlighting the long-term benefits of ethical consumerism despite the higher upfront cost.",
        },
      ],
    },
    {
      id: "cultural-exchange",
      title: "Cultural Exchange Programs",
      case: "A public university initiates an international student exchange program to enhance cross-cultural understanding among students. The program allows students to study abroad for a semester at a partner institution, gain unique insights into different education systems, and participate in a variety of cultural immersion activities. Participants also have the opportunity to develop language skills, form international friendships, and prepare for a globalized job market. However, the program faces several challenges, including managing the logistical aspects of travel, ensuring student safety, accommodating academic credit systems across different countries, and providing sufficient support to students coping with culture shock.",
      assessment: [
        {
          question:
            "How can international student exchange programs benefit participants personally and academically?",
          expectation:
            "Respondents should examine the impact of studying abroad on student growth, such as enhanced cultural understanding, improved language proficiency, and the development of global networks and competencies.",
        },
        {
          question:
            "What are the main challenges faced by institutions when organizing international student exchange programs?",
          expectation:
            "Candidates are expected to explore and articulate the complex logistical, academic, and socio-emotional difficulties involved in executing and maintaining exchange programs.",
        },
        {
          question:
            "As a program coordinator, what strategies would you implement to overcome the challenges associated with cultural exchange programs?",
          expectation:
            "For this question, the candidate should propose innovative and practical solutions for the common challenges faced, including ideas for pre-departure training, on-site support systems, and mechanisms to recognize academic achievements abroad.",
        },
      ],
    },
    {
      id: "online-learning-vs-traditional-classrooms",
      title: "Online Learning vs. Traditional Classrooms",
      case: "As the world continues to embrace digital transformation, the education sector has been presented with a choice: to continue with the traditional classroom setting or to integrate online learning methodologies. A rising edtech company is looking to launch a comprehensive online education platform that promises flexibility, accessibility, and personalized learning experiences using data-driven approaches and adaptive learning technologies. Proponents argue that online learning eliminates geographical barriers and provides resources for self-paced education, while opponents raise concerns about the lack of social interaction, potential distractions, and the effectiveness of online assessments.",
      assessment: [
        {
          question:
            "What are some potential benefits and limitations of online learning platforms when compared to traditional classroom education?",
          expectation:
            "Candidates should analyze both the advantages such as increased accessibility and flexibility, and the drawbacks like reduced face-to-face interaction and potential for decreased engagement.",
        },
        {
          question:
            "How could the absence of a physical classroom environment impact a student's learning experience and outcomes?",
          expectation:
            "Interviewees ought to discuss the importance of social interaction, the role of discipline in a learning environment, and the potential challenges of online assessment integrity.",
        },
        {
          question:
            "As an education specialist, how would you address the challenge of ensuring effective learning and student engagement in an online platform?",
          expectation:
            "For this assessment, candidates need to propose strategies which might include interactive elements, engagement analytics, peer-to-peer learning facilitation, or hybrid models combining online and in-person elements.",
        },
      ],
    },
    {
      id: "social-media-influence",
      title: "The Role of Social Media in Society",
      case: "As social networking continues to grow, academic institutions have observed the way communication and peer relationships among students are affected by platforms such as Facebook, Instagram, and Twitter. A particular university launches a research project to analyze the effects of social media on student relationships and their access to news and information. The project aims to determine the ways in which social media platforms can enhance learning and connection, as well as how they may contribute to misinformation and social isolation.",
      assessment: [
        {
          question:
            "What are the potential positive impacts of social media on relationships and information dissemination among students?",
          expectation:
            "Candidates should discuss the ways in which social media can promote connectivity, foster virtual learning communities, and provide a platform for the quick exchange of information.",
        },
        {
          question:
            "How might social media contribute to the spread of misinformation and how does it affect students’ perceptions?",
          expectation:
            "Interviewees will need to explore the risks of misinformation on social media and its potential impact on students' perspectives and decision-making.",
        },
        {
          question:
            "What measures can academic institutions take to mitigate the negative effects of social media without limiting its benefits?",
          expectation:
            "For the third question, candidates should suggest actionable strategies that institutions might implement to minimize harm, such as digital literacy programs, while preserving the positive aspects of social networking.",
        },
      ],
    },
    {
      id: "work-life-balance",
      title: "Achieving Work-Life Harmony",
      case: "A rapidly growing international marketing firm has been experiencing high employee turnover rates. Internal surveys reveal that employees are struggling with work-life balance due to unpredictable work hours and continuous high-pressure deadlines. The firm's leadership recognizes the need to implement policies that promote a healthier work-life interface to maintain employee satisfaction and productivity. They are exploring various strategies including flexible work schedules, remote working options, and wellness programs. The challenge lies in implementing these strategies effectively without compromising the client deliverables and company's aggressive growth targets.",
      assessment: [
        {
          question:
            "What benefits and challenges can arise when a company introduces flexible work schedules and remote working options?",
          expectation:
            "Interviewers are expected to articulate the advantages, such as increased employee satisfaction and retention, and potential drawbacks, like potential decreases in collaboration and supervision challenges.",
        },
        {
          question:
            "How can a wellness program contribute to a better work-life balance for employees?",
          expectation:
            "Candidates should discuss how wellness programs can improve employees' mental and physical health, reduce burnout and ultimately lead to increased productivity and job satisfaction.",
        },
        {
          question:
            "As a manager, how would you ensure these work-life balance strategies are executed without affecting the company's growth and client satisfaction?",
          expectation:
            "The candidate will need to demonstrate a strategic approach to integrating work-life balance initiatives in a manner that supports business objectives and maintains high client service standards.",
        },
      ],
    },
    {
      id: "transportation-innovation",
      title: "Innovations in Transportation",
      case: "The local government has partnered with a tech company to introduce an advanced public transportation system featuring a fleet of electric buses and a network of high-speed trains. The system aims to reduce urban traffic congestion and cut down on pollution by providing an efficient and cleaner mode of transport. Electric buses are expected to be integrated with smart technologies to optimize routes in real-time and provide passengers with accurate travel information. High-speed train lines are designed to connect major urban centers quickly, reducing the need for short-haul flights and encouraging mass transit. However, challenges exist, including the substantial initial investment, the need for ongoing infrastructure maintenance, and potential resistance from communities affected by the new routes.",
      assessment: [
        {
          question:
            "What are the anticipated environmental and social benefits of implementing electric buses and high-speed trains?",
          expectation:
            "Candidates should discuss the reduction in greenhouse gas emissions, improvements in air quality, and the promotion of social equity by providing accessible high-speed transportation options.",
        },
        {
          question:
            "How might the initial costs and infrastructure changes be justified to gain public and political support for the project?",
          expectation:
            "The interviewee is expected to explore economic analyses including cost-benefit assessments, long-term savings, job creation, and the improvement of quality of life, which could help in gaining support.",
        },
        {
          question:
            "As a project manager, how would you approach resolving community concerns related to the creation of new train lines that may disrupt existing neighborhoods?",
          expectation:
            "The response should cover strategies like community engagement, transparent communication, compensation measures, improvements to local infrastructure, and highlighting broader societal benefits.",
        },
      ],
    },
  ],
);
