import { Tag, Text, Tooltip } from "@chakra-ui/react";
import { PromptTemplateDTO } from "../../../../database/entity/PromptTemplate";
import { PromptTemplateConfigType } from "../../../../configuration/types";
import { useSettings } from "../../../../hooks/use-settings";
import { useContext } from "react";
import { LocalizationContext } from "../../../../hooks/use-localization";
interface Props {
  templates: PromptTemplateDTO[];
  type: PromptTemplateConfigType;
}

export default function GenericModalBody(props: Props) {
  const { l } = useContext(LocalizationContext);
  const { settings } = useSettings();

  const onClick = (slug: string) => {
    window.location.href = `/app/chat?t=${slug}`;
  };

  const templates = props.templates
    .filter((t) => t.type === props.type)
    .filter((t) => t.status === "listed")
    .filter((t) => !t.proficiency || t.proficiency === settings.practiceLanguageProficiency);

  return (
    <div>
      {templates.length > 0 ? (
        templates.map((item, i) => (
          <Tag
            size={"md"}
            key={i}
            colorScheme={"brand.secondary"}
            m={1}
            onClick={() => onClick(item.slug)}
            _hover={{ opacity: 0.7, cursor: "pointer" }}
          >
            <Tooltip label={item.description} hasArrow placement="top" openDelay={200} mb={2}>
              {item.title}
            </Tooltip>
          </Tag>
        ))
      ) : (
        <Text fontSize="sm" color="brand.blue.500">
          {l["dashboard.template.modal.noContent"]}
        </Text>
      )}
    </div>
  );
}
