import { ConversationMessageRole, ConversationMessage } from "../../../types/conversation";
import { Settings } from "../../../types/settings";
import { fetchTranslation } from "../../fetch-translation";

import { v4 as uuidv4 } from "uuid";
import { PromptTemplateDTO } from "../../../database/entity/PromptTemplate";

export async function getTranslatedMessage(enMessage: string, settings: Settings) {
  if (settings.practiceLanguage === "en-US" || settings.practiceLanguage === "en-GB") {
    return enMessage;
  }

  const text = await fetchTranslation(enMessage, "en-US", settings.practiceLanguage!);

  return text;
}

export function createMessage(
  content: string,
  role: ConversationMessageRole = "system",
): ConversationMessage {
  return {
    role,
    content,
    id: uuidv4(),
  };
}

export function createTagMessageFromPromptTemplate(template: PromptTemplateDTO) {
  return createMessage(getTemplateTagFromPromptTemplate(template));
}

export function getTemplateTagFromPromptTemplate(template: PromptTemplateDTO) {
  return `[prompt-template=${template.slug}]`;
}

export const freeTalkTag = "[free-talk]";
export const onBoardingTag = "[on-boarding]";
export const lessonTag = "[lesson]";
export const lessonTagWithSlug = (slug: string) => `[lesson=${slug}]`;
